.circle-image {
  width: 33%;
  min-width: 300px;
  margin: 20px 0 20px 0;
  padding: 0;
  display: inline-block;
}

.circle-image img {
  width: 150px;
  border-radius: 50%;
}

.circle-image p {
  margin: 2px 0 2px 0;
}

.circle-container {
  font-size: 24px;
  display: inline-block;
  padding: 0;
  max-width: 1000px;

}

.caption {
  font-size: 16px;
  color: rgb(50, 50, 50)
}