.home {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.hero-banner {
  background: linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.5)),url('../images/sara_rock-min.jpg');
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 150px 0 150px 0;
}

.hero-content-container {
  text-align: center;
  display: block;
  width: 100%;
}

.hero-content-container p {
  font-family: 'Lobster','Times New Roman', Times, serif;
  letter-spacing: 4px;
  font-size: 3em;
  color: rgba(235, 235, 235, 1.0);
  padding: 0 32px 0 32px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

#quote-text {
  text-align: left;
  font-size: 2.5em;
}

#quote-author {
  text-align: right;
}

.flex-container {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  max-width: 1100px;
  width: 100%;
  margin: 1em auto 1em auto;
  align-self: flex-start;
  justify-content: center;
}

.band-description {
  margin: 2em 0 2em 0;
}

.band-description h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 32px;
  margin: 4px 0 4px 0;
}

.band-description p {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 16px;
  margin: 0;
}



.band-name-container {
  text-align: left;
  margin: 1em 2em 1em 2em;
  max-width: 850px;
  flex-grow: 3;
}

@media (max-width: 930px) {
  .band-name-container {
    margin: 1em 3em 1em 3em;
  }
}

.band-name-container h2 {
  font-family: 'Crimson Text', Times, serif;
  font-weight: normal;
  font-size: 56px;
  text-transform: uppercase;
  letter-spacing: 4px; 
  margin: 0;
  max-width: 450px;
}

.band-name-container p {
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 36px;
  margin: 0;
  text-transform: lowercase;
  max-width: 450px;
}

.social-container {
  flex-grow: 1;
  margin: 1em 2em 1em 2em;
}

.social-container p {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
}

.social-container iframe {
  background-color: rgb(235, 235, 235);
}