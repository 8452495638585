html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  background-color: black;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color:rgba(0, 0, 0, 0.9);
  position: relative;
}

h2 {
  font-family: 'Cabin', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 6px;
}

p {
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  min-height: 100%;
  height: auto !important; /* IE6 support */
  height: 100%;
  width: 100%;
  background-color: white;
}


.navbar {
  width: 100%;
  background-color: #000000;
  background: -webkit-linear-gradient(bottom,rgb(0, 0, 0),rgba(255, 255, 255, 0.0)),url(/static/media/jefferson-santos-450408-unsplash-guitar.f70d6bdf.jpg);
  background: linear-gradient(0deg,rgb(0, 0, 0),rgba(255, 255, 255, 0.0)),url(/static/media/jefferson-santos-450408-unsplash-guitar.f70d6bdf.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: none;
  position: relative;
}

.navbar button {
  display: inline-block;
  background: none;
  border: none;
  border-bottom: solid transparent 2px;
  -webkit-transition: border-bottom 1s;
  transition: border-bottom 1s;
  color:rgba(255, 255, 255, 1.0);
  width: 6em;
  height: 3em;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Cabin', Arial, sans-serif;
  margin: 12px;
}

.navbar button:focus {
  outline: 0;
  border-bottom: solid white 2px;
}

.navbar button:hover {
  border-bottom: solid white 2px;
  cursor: pointer;
}

.navbar-fade {
  background: -webkit-linear-gradient(bottom, white , black);
  background: linear-gradient(0deg, white , black);
  width: 100%;
  height: 1em;
  border: none;
  position: relative;
}

.navbar-wrapper {
  background-color: white;
  margin-bottom: 2em;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

.nav-logo {
  width: 75%;
  height: auto;
  max-width: 1050px;
  max-height: 90%;
  display: block;
  margin: auto;
}

.menu {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 6px;
}

.footer-fade {
  background: -webkit-linear-gradient(bottom, black, white);
  background: linear-gradient(0deg, black, white);
  width: 100%;
  height: 2em;
  
}

.footer {
  background-color: black;
  padding: 2em;
}

.footer p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.circle-image {
  width: 33%;
  min-width: 300px;
  margin: 20px 0 20px 0;
  padding: 0;
  display: inline-block;
}

.circle-image img {
  width: 150px;
  border-radius: 50%;
}

.circle-image p {
  margin: 2px 0 2px 0;
}

.circle-container {
  font-size: 24px;
  display: inline-block;
  padding: 0;
  max-width: 1000px;

}

.caption {
  font-size: 16px;
  color: rgb(50, 50, 50)
}
.home {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.hero-banner {
  background: -webkit-linear-gradient(bottom,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.5)),url(/static/media/sara_rock-min.fa278718.jpg);
  background: linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.5)),url(/static/media/sara_rock-min.fa278718.jpg);
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 150px 0 150px 0;
}

.hero-content-container {
  text-align: center;
  display: block;
  width: 100%;
}

.hero-content-container p {
  font-family: 'Lobster','Times New Roman', Times, serif;
  letter-spacing: 4px;
  font-size: 3em;
  color: rgba(235, 235, 235, 1.0);
  padding: 0 32px 0 32px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

#quote-text {
  text-align: left;
  font-size: 2.5em;
}

#quote-author {
  text-align: right;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* flex-wrap: wrap; */
  max-width: 1100px;
  width: 100%;
  margin: 1em auto 1em auto;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}

.band-description {
  margin: 2em 0 2em 0;
}

.band-description h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 32px;
  margin: 4px 0 4px 0;
}

.band-description p {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 16px;
  margin: 0;
}



.band-name-container {
  text-align: left;
  margin: 1em 2em 1em 2em;
  max-width: 850px;
  -webkit-flex-grow: 3;
          flex-grow: 3;
}

@media (max-width: 930px) {
  .band-name-container {
    margin: 1em 3em 1em 3em;
  }
}

.band-name-container h2 {
  font-family: 'Crimson Text', Times, serif;
  font-weight: normal;
  font-size: 56px;
  text-transform: uppercase;
  letter-spacing: 4px; 
  margin: 0;
  max-width: 450px;
}

.band-name-container p {
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 36px;
  margin: 0;
  text-transform: lowercase;
  max-width: 450px;
}

.social-container {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: 1em 2em 1em 2em;
}

.social-container p {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
}

.social-container iframe {
  background-color: rgb(235, 235, 235);
}
.gallery-container {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.gallery {
  max-width: 1200px;
  margin: 1em auto 1em auto;
  text-align: center;
}

.gallery-image-container {
  display: inline-block;
  padding: 1em;
  max-width: 540px;
  max-height: 360px;
}

.gallery-image-container img {
  width: 100%;
  height: auto;
  background-color: rgb(235, 235, 235);
}

.lightbox {
  display: none;
  position: fixed;
  z-index:0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.lightbox img {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 85%;
  height: auto;
}

.close-button {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 45px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  cursor: pointer;
}
.music-container {
  width: 100%;
  max-width: 1100px;
  text-align: center;
  margin: auto;
}

.music-section {
  margin: 1em 0 0 0;
}

.music-links {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 80%;
  margin: 2em 0 2em 0;
}

.music-link__item {
  background-color: black;
  padding: 1em 3em 1em 3em;
  text-align: center;
}

.music-link__item h3 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.music-links a {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-family: 'Cabin', Arial, sans-serif;
  letter-spacing: 2px;
}

.music-container h3 {
  text-transform: uppercase;
  font-family: 'Cabin', Arial, sans-serif;
  letter-spacing: 2px;
}

.iframe-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 30px; 
  height: 0; 
  overflow: hidden;
  margin: 2em 3em 2em 3em;
}

.iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/static/media/loading-spin-min.7e2885af.gif);
  background-repeat: no-repeat;
  background-position: center center;
}


.event {
  margin: 1em auto 0 auto;
  padding: 0 0 1em 0;
  text-align: center;
  width: 70%;
  border: 2px solid black;
}

.event p {
  font-size: 20px;
}

.events-container {
  max-width: 1200px;
  margin: 0 auto 0 auto;
  padding: 0 0 2em 0;
  text-align: center;
}

.events-container p {
  padding-bottom: 1em;
}

.events-container iframe {
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  height: 500px;
  display: block;
  background-color:rgb(235, 235, 235);
}

.date-container {
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 auto 0 auto;
  padding: 1em 0.5em 1em 0.5em;
}
.flex-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 1100px;
  width: 100%;
  margin: 1em auto 1em auto;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}

.mail-container {
  -webkit-flex-grow: 6;
          flex-grow: 6;
  text-align: left;
  margin: auto 1em auto 1em;
  max-width: 900px;
}

.contact-container {
  margin-bottom: 2em;
}

.contact-form-container {
  width: 90%;
}

.contact-form-container h3 {
  width: 100%;
  height: auto;
  font-family: 'Cabin', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.contact-form-container input[type="text"] {
  width: 100%;
  height: auto;
  padding: 4px;
  border-radius: 4px;
  border: solid 2px grey;
  padding: 10px 6px 10px 6px;
  font-family: Arial, sans-serif;
  font-size: 18px;
}

.contact-form-container input[type="submit"] {
  padding: 1em;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: 'Cabin', Arial, sans-serif;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.contact-form-container input[type="submit"]:hover,
.contact-form-container input[type="submit"]:focus {
  cursor: pointer;
}

.contact-form-container textarea {
  width: 100%;
  height: auto;
  min-height: 200px;
  padding: 10px 6px 10px 6px;
  border-radius: 4px;
  border: solid 2px grey;
  resize: none;
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form-container p {
  text-align: right;
  color: rgb(80, 80, 80);
  font-size: 14px;
}

.social-reach-container {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: auto 1em auto 1em;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.social-reach-container iframe {
  background-color: rgb(235, 235, 235);
}
.notfound {
  padding: 2em;
}
