.navbar {
  width: 100%;
  background-color: #000000;
  background: linear-gradient(0deg,rgb(0, 0, 0),rgba(255, 255, 255, 0.0)),url('../images/jefferson-santos-450408-unsplash-guitar.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: none;
  position: relative;
}

.navbar button {
  display: inline-block;
  background: none;
  border: none;
  border-bottom: solid transparent 2px;
  transition: border-bottom 1s;
  color:rgba(255, 255, 255, 1.0);
  width: 6em;
  height: 3em;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Cabin', Arial, sans-serif;
  margin: 12px;
}

.navbar button:focus {
  outline: 0;
  border-bottom: solid white 2px;
}

.navbar button:hover {
  border-bottom: solid white 2px;
  cursor: pointer;
}

.navbar-fade {
  background: linear-gradient(0deg, white , black);
  width: 100%;
  height: 1em;
  border: none;
  position: relative;
}

.navbar-wrapper {
  background-color: white;
  margin-bottom: 2em;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

.nav-logo {
  width: 75%;
  height: auto;
  max-width: 1050px;
  max-height: 90%;
  display: block;
  margin: auto;
}

.menu {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 6px;
}

.footer-fade {
  background: linear-gradient(0deg, black, white);
  width: 100%;
  height: 2em;
  
}

.footer {
  background-color: black;
  padding: 2em;
}

.footer p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}