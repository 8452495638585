.gallery-container {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.gallery {
  max-width: 1200px;
  margin: 1em auto 1em auto;
  text-align: center;
}

.gallery-image-container {
  display: inline-block;
  padding: 1em;
  max-width: 540px;
  max-height: 360px;
}

.gallery-image-container img {
  width: 100%;
  height: auto;
  background-color: rgb(235, 235, 235);
}

.lightbox {
  display: none;
  position: fixed;
  z-index:0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.lightbox img {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 85%;
  height: auto;
}

.close-button {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 45px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  cursor: pointer;
}