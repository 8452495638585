
.App {
  text-align: center;
  min-height: 100%;
  height: auto !important; /* IE6 support */
  height: 100%;
  width: 100%;
  background-color: white;
}

