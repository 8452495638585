html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  background-color: black;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color:rgba(0, 0, 0, 0.9);
  position: relative;
}

h2 {
  font-family: 'Cabin', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 6px;
}

p {
  font-family: Arial, sans-serif;
}